import { isUndefined, omitBy } from 'lodash';
import { useQuery } from 'react-query';

import { queryClient } from '@jernia/shared/state/query-client';

import { config } from '@jernia/shared/lib/config';

import { createStore } from '../createStore';

import type { Cart } from '@jernia/shared/types/rest/cart';
import { VippsLoginResultData } from '@jernia/shared/types/rest/vipps';

type CartState = {
  cartId: null | string;
  cartUid: null | string;
  getCartId: () => string | null;
  getCartUid: () => string | null;
  paymentMethod: PaymentMode;
  tmpAddressInfo: {
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  setCart: (cart: Cart) => void;
  setCartFromVippsLogin: (
    loginResult: Extract<
      VippsLoginResultData,
      { status: 'AUTHENTICATED' | 'NEW_ACCOUNT' }
    >
  ) => void;
  clearCart: () => void;
  setTmpAddressInfo: (addressInfo: CartState['tmpAddressInfo']) => void;
  setPaymentMethod: (paymentMethod: PaymentMode) => void;
};

export enum PaymentMode {
  vipps = 'vippsB2h',
  pos = 'posB2h',
}

function getInitialTmpAddressInfo(): CartState['tmpAddressInfo'] {
  if (typeof localStorage !== 'undefined') {
    try {
      const info = localStorage.getItem('tmpAddressInfo');
      if (info) {
        return JSON.parse(info);
      }
    } catch (e) {
      console.error('Failed to parse tmpAddressInfo from localStorage');
    }
  }

  return {
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
  };
}

export const useCartStore = createStore<CartState>((set, get) => ({
  cartId:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('cartId') || null
      : null,
  getCartId: () => get().cartId,
  cartUid:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('cartUid') || 'anonymous'
      : 'anonymous',
  getCartUid: () => get().cartUid,
  paymentMethod: PaymentMode.vipps,

  tmpAddressInfo: getInitialTmpAddressInfo(),

  setCart: (cart: Cart) => {
    const cartId =
      !cart.user || cart.user?.uid == 'anonymous' ? cart.guid : cart.code;
    const cartUid = cart.user?.uid ?? 'anonymous';

    set({
      cartId,
      cartUid,
    });

    localStorage.setItem('cartId', cartId);
    localStorage.setItem('cartUid', cartUid);
  },

  setCartFromVippsLogin: (
    loginResult: Extract<
      VippsLoginResultData,
      { status: 'AUTHENTICATED' | 'NEW_ACCOUNT' }
    >
  ) => {
    set({
      cartId: loginResult.cart.code,
      cartUid: loginResult.userId,
    });

    localStorage.setItem('cartId', loginResult.cart.code);
    localStorage.setItem('cartUid', loginResult.userId);
  },

  setTmpAddressInfo(tmpAddressInfo) {
    set({ tmpAddressInfo });

    localStorage.setItem('tmpAddressInfo', JSON.stringify(tmpAddressInfo));
  },

  clearCart: () => {
    set({ cartId: null, cartUid: null });

    localStorage.removeItem('cartId');
    localStorage.removeItem('cartUid');

    setTimeout(() => {
      // Allow the state change to propagate before re-fetching
      queryClient.invalidateQueries('cart');
    }, 1);
  },

  setPaymentMethod(paymentMethod) {
    set({ paymentMethod });
  },
}));

export function useStores({
  query,
  geocode,
  currentPage,
  enabled = true,
}: {
  query?: string;
  currentPage?: string;
  geocode?: { lat: string; long: string };
  enabled?: boolean;
}) {
  return useQuery(
    ['stores', query],
    async () => {
      const params = {
        currentPage,
        query,
      };

      const urlParams = new URLSearchParams(omitBy(params, isUndefined));

      const url = `${config.apiBaseUrl}/stores?${urlParams.toString()}`;

      const response = await fetch(url);

      return response.json();
    },
    {
      enabled,
    }
  );
}
