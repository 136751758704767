import * as Sentry from '@sentry/nextjs';

import { useAuthStore } from './auth';

interface FetchOptions extends Omit<RequestInit, 'body'> {
  /**
   * Request will be sent as form data with Conent-Type: application/x-www-form-urlencoded.
   * Body may be passed as an object of key => value which will be formatted
   * properly.
   */
  sendAsForm?: boolean;
  sendAsMultipart?: boolean;
  body?:
    | RequestInit['body']
    | {
        [key: string]: string | number;
      }
    | FormData
    | FileList
    | {
        attachments: any;
      };
  queryParams?: Record<string, string | boolean | number>;
}

async function getAccessToken() {
  const { accessToken } = useAuthStore.getState();

  if (accessToken) {
    return accessToken;
  }

  // @TODO: Reject after a timeout
  return await new Promise((resolve) => {
    const unsub = useAuthStore.subscribe((state) => {
      if (state.accessToken) {
        resolve(accessToken);
        unsub();
      }
    });
  });
}

export async function fetchWithAuth(
  url: string,
  opts: FetchOptions = {}
): Promise<Response> {
  const method = opts.method ?? opts.sendAsForm ? 'POST' : 'GET';

  const headers = new Headers({
    Accept: 'application/json',
    ...opts.headers,
  });

  const accessToken = await getAccessToken();

  if (!accessToken) {
    return Promise.reject('Not authorized');
  }

  headers.set('Authorization', `Bearer ${accessToken}`);

  if (
    method !== 'GET' &&
    !headers.has('content-type') &&
    !opts.sendAsForm &&
    !opts.sendAsMultipart
  ) {
    headers.set('Content-Type', 'application/json');
  }

  if (opts.sendAsForm) {
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
  }

  if (opts.queryParams) {
    const queryParamsString = Object.entries(opts.queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    url = `${url}?${queryParamsString}`;
  }

  if (
    opts.sendAsForm &&
    opts.body &&
    typeof opts.body === 'object' &&
    opts.body !== null
  ) {
    opts.body = Object.entries(opts.body)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  const options = {
    method,
    ...(opts as RequestInit),
    headers,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    switch (response.status) {
      case 401:
        // @TODO: Handle refresh token (error contains InvalidTokenError).
        Sentry.captureException(`401 returned from [${method}] ${url}`);
        useAuthStore.getState().logout();
        break;
    }

    if (response.headers.get('Content-Type')?.includes('application/json')) {
      const cloned = response.clone();
      const data = await cloned.json();
      Sentry.withScope((scope) => {
        console.error(`Failed to fetch ${url}`, data);

        scope.setExtra('url', url);
        scope.setExtra('method', method);

        scope.setExtra('requestBody', opts.body);
        scope.setExtra('responseBody', JSON.stringify(data));

        Sentry.captureException(new Error(`Failed to fetch ${url}`));
      });
    }
  }

  return response;
}
