import * as Sentry from '@sentry/nextjs';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import create from 'zustand';

import { config } from '@jernia/shared/lib/config';

import { Company, CompanyRegistrationDataByName } from '../types/rest/company';
import { ConsentTemplatesResponse } from '../types/rest/consent';
import { User } from '../types/rest/user';
import { useCartStore } from './cart/store';
import { useCustomerStore } from './customer';
import { fetchWithAuth } from './fetch';

import type { AuthenticationResponseSuccess } from '@jernia/shared/types/rest/authentication';
import type { EmployeeSuccessResponse } from '@jernia/shared/types/rest/emloyee';
import { PointOfService, Stores } from '@jernia/shared/types/rest/stores';

type AuthState = {
  accessToken: string;
  userId: string;

  setAccessToken: (accessToken: string) => void;
  loginSuccess: (result: AuthenticationResponseSuccess) => void;
  setLoggedInUser: (userId: string) => void;
  resetLoggedInUser: () => void;
  logout: () => void;
};

export const useAuthStore = create<AuthState>((set) => ({
  accessToken: '',
  userId:
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('userId') || 'anonymous'
      : 'anonymous',

  setAccessToken: (accessToken) => set({ accessToken }),

  loginSuccess: (result: AuthenticationResponseSuccess) => {
    set({ accessToken: result['access_token'] });

    localStorage.setItem(
      'authSession',
      JSON.stringify({
        accessToken: result['access_token'],
        refreshToken: result['refresh_token'],
        expiresAt: +new Date() + result['expires_in'] * 1000,
      })
    );
  },

  setLoggedInUser(userId: string) {
    set({ userId });
    localStorage.setItem('userId', userId);
  },

  resetLoggedInUser() {
    set({ userId: 'anonymous' }), localStorage.removeItem('userId');
  },

  logout() {
    set({ accessToken: '', userId: 'anonymous' });

    localStorage.removeItem('userId');
    localStorage.removeItem('authSession');
    localStorage.removeItem('tmpAddressInfo');

    useCartStore.getState().clearCart();

    if (config.site === 'self-checkout') {
      Router.push('/');
    } else {
      Router.push('/login');
    }
  },
}));

export function useLoggedInEmployee() {
  return useQuery<EmployeeSuccessResponse>('employee', async () => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/asagents/current`
    );

    if (!response.ok) {
      throw await response.json();
    }

    return await response.json();
  });
}

export function useLoggedInUserQuery() {
  const { userId } = useAuthStore();

  return useQuery<User>(
    ['user', userId],
    async () => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/users/${userId}`
      );

      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      enabled: userId !== 'anonymous',
    }
  );
}

export function useStores() {
  return useQuery<PointOfService[]>(
    'stores',
    async () => {
      const resp = await fetch(`${config.apiBaseUrl}/stores/jerniaHub`);
      const { pointOfServices }: Stores = await resp.json();
      return pointOfServices;
    },
    {
      placeholderData: [],
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}

export function useStore(storeId: string) {
  return useQuery<PointOfService>(
    ['store', storeId],
    async () => {
      const resp = await fetch(
        `${config.apiBaseUrl}/stores/${storeId}?fields=FULL`
      );
      const pointOfService: PointOfService = await resp.json();
      return pointOfService;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!storeId,
    }
  );
}

export function useChangeStoreMutation() {
  type Opts = {
    storeId: string;
  };

  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, Opts>(
    async ({ storeId }) => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/asagents/current/store?store=${storeId}`,
        {
          method: 'PATCH',
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employee');
      },
    }
  );
}

export function useRequireAuthentication(): {
  showSplash: boolean;
} {
  const router = useRouter();
  const queryClient = useQueryClient();

  const { setAccessToken, loginSuccess, resetLoggedInUser } = useAuthStore();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  type Result =
    | {
        type: 'valid';
        accessToken: string;
      }
    | {
        type: 'refreshed';
        result: AuthenticationResponseSuccess;
      };

  const restoreSessionMutation = useMutation<Result>(
    async () => {
      const sessionString = localStorage.getItem('authSession');
      if (!sessionString) {
        throw new Error('No session');
      }

      // @TODO: Type this
      let session;
      try {
        session = JSON.parse(sessionString);
      } catch (e) {
        throw new Error(
          `Failed to parse invalid session string: ${sessionString}`
        );
      }

      if (!session) {
        throw new Error('No session string');
      }

      if (session.expiresAt < +new Date()) {
        const response = await fetch('/api/login/refresh', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: session.refreshToken,
          }),
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(
            `Failed to authenticate user. ${JSON.stringify(result)}`
          );
        }

        return {
          type: 'refreshed',
          result,
        };
      }

      return {
        type: 'valid',
        accessToken: session.accessToken,
      };
    },
    {
      onSuccess: async (result) => {
        switch (result.type) {
          case 'valid':
            setAccessToken(result.accessToken);
            break;
          case 'refreshed':
            loginSuccess(result.result);
            break;
        }

        setIsAuthenticated(true);

        try {
          await useCustomerStore
            .getState()
            .loginAsCustomer(useAuthStore.getState().userId);
        } catch (e) {
          resetLoggedInUser();
        }

        queryClient.invalidateQueries('cart');
      },
      onError: (e) => {
        Sentry.captureException(e);
        console.error(e);

        router.push('/login');
      },
    }
  );

  const { mutate } = restoreSessionMutation;
  useEffect(() => {
    if (
      router.asPath.startsWith('/login') ||
      router.asPath.startsWith('/_vipps-checkout')
    ) {
      return;
    }

    mutate();
  }, [mutate, router]);

  return {
    showSplash:
      !isAuthenticated &&
      !router.asPath.startsWith('/login') &&
      !router.asPath.startsWith('/_vipps-checkout'),
  };
}

export type ConsentTemplate = {
  consentTemplateId: string;
  consentTemplateVersion: number;
  consentGiven: string;
};

export type RegisterUserProps = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  pwd: string;
  emailConsent: ConsentTemplate;
  smsConsent: ConsentTemplate;
  trackingConsent: ConsentTemplate;
  orgNumber?: string;
};

// Recursively convert params to url encoded form data
function urlEncodedFormData(params, parentKey?: string) {
  if (params && typeof params === 'object') {
    return Object.keys(params)
      .map((key) => {
        return urlEncodedFormData(
          params[key],
          parentKey ? `${parentKey}.${key}` : key
        );
      })
      .join('&');
  } else {
    const value = params == null ? '' : params;

    return `${encodeURIComponent(parentKey)}=${encodeURIComponent(value)}`;
  }
}

export function useRegisterUserMutation() {
  type ResponseType = {
    ok: boolean;
    data: {
      errorMessage?: string;
      fieldErrors?: {
        field: string;
        message: string;
      }[];
    };
  };

  return useMutation<ResponseType, unknown, RegisterUserProps>(
    async (payload) => {
      const CSRFToken = window.ACC.config.CSRFToken ?? '';
      const params = {
        ...payload,
        // Copy password because the backend expects two fields
        checkPwd: payload.pwd,
        _csrf: CSRFToken,
      };

      const url = payload.orgNumber
        ? `/account/b2b/register`
        : '/account/register';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': CSRFToken,
        },
        body: urlEncodedFormData(params),
      });

      return { ok: response.ok, data: await response.json() };
    }
  );
}

export function useAvailableConsentTemplatesQuery() {
  return useQuery<ConsentTemplatesResponse['consentTemplates']>(
    ['consentTemplates'],
    async () => {
      const response = await fetch(`/account/consent-templates`);
      return await response.json();
    }
  );
}

type Variant = 'b2c' | 'b2b';

export function useUserLoginMutation(variant: Variant = 'b2c') {
  type UserLoginProps = {
    j_username: string;
    j_password: string;
  };

  return useMutation<Response, unknown, UserLoginProps>(async (props) => {
    const CSRFToken = window.ACC.config.CSRFToken ?? '';
    const params = {
      ...props,
      _csrf: CSRFToken,
    };

    const url =
      variant === 'b2b'
        ? '/b2b/j_spring_security_check'
        : '/j_spring_security_check';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': CSRFToken,
      },
      body: urlEncodedFormData(params),
    });

    return response;
  });
}

type UserForgotPasswordProps = {
  email: string;
};
export function useUserForgotPasswordMutation(variant: Variant = 'b2c') {
  return useMutation<Response, unknown, UserForgotPasswordProps>(
    async (props) => {
      const CSRFToken = window.ACC.config.CSRFToken ?? '';

      const url =
        variant === 'b2b' ? '/login/pw/request/b2b' : '/login/pw/request';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': CSRFToken,
        },
        body: urlEncodedFormData(props),
      });

      return response;
    }
  );
}

export async function getOrdersAccessToken() {
  const accessTokenRes = await fetch(
    `${process.env.OAUTH_SERVER}/authorizationserver/oauth/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Object.entries({
        client_id: process.env.OAUTH_CLIENT_ID_ORDERS,
        client_secret: process.env.OAUTH_CLIENT_SECRET,
        grant_type: 'client_credentials',
      })
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    }
  );

  const token = await accessTokenRes.json();

  return token;
}

type ResetPasswordFormData = {
  token: string;
  pwd: string;
  checkPwd: string;
};

export function useResetPasswordMutation() {
  return useMutation<Response, unknown, ResetPasswordFormData>(
    async (props) => {
      const CSRFToken = window.ACC.config.CSRFToken ?? '';

      const url = '/login/pw/change';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': CSRFToken,
        },
        body: urlEncodedFormData(props),
      });

      return response;
    }
  );
}
type B2bRegistrationDataByNumberProps = {
  number: string;
};

export function useB2bRegistrationDataByNumberMutation() {
  return useMutation<Response, unknown, B2bRegistrationDataByNumberProps>(
    async (props) => {
      const url = `/account/b2b/registration-data/${props.number}`;

      const response = await fetch(url);

      return response;
    }
  );
}

export function useB2bCompanyDataQuery(number: string) {
  return useQuery<Company>(
    ['b2bCompanyData', number],
    async () => {
      const url = `/account/b2b/registration-data/${number}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return await response.json();
    },
    {
      enabled: !!number,
    }
  );
}

type B2bRegistrationDataByNameProps = {
  query: string;
  currentPage?: number;
  pageSize?: number;
};

export function useB2bRegistrationDataByQueryMutation() {
  return useMutation<
    CompanyRegistrationDataByName,
    unknown,
    B2bRegistrationDataByNameProps
  >(
    async (props) => {
      const { query, currentPage = 0, pageSize = 10 } = props;
      const queryParams = urlEncodedFormData({
        query,
        currentPage,
        pageSize,
      });

      const url = `/account/b2b/registration-data?${queryParams}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response not ok');
      }

      return await response.json();
    },
    {
      mutationKey: '/account/b2b/registration-data',
    }
  );
}

export const useGetVippsRedirectUrlMutation = () => {
  return useMutation(async () => {
    const response = await fetch(
      '/vipps/login/authentication-success-redirect'
    );

    return response;
  });
};

export const useVippsLoginExistingAccountMutation = () => {
  return useMutation(async () => {
    const CSRFToken = window.ACC.config.CSRFToken ?? '';

    const response = await fetch('/vipps/login/existing-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': CSRFToken,
      },
    });

    // if 302 redirect to location header
    if (response.status === 302) {
      return response.headers.get('Location');
    }

    return '/my-account';
  });
};

export const useVippsLoginRegisterMutation = () => {
  return useMutation(async () => {
    const CSRFToken = window.ACC.config.CSRFToken ?? '';

    const response = await fetch('/vipps/login/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': CSRFToken,
      },
    });
    return response;
  });
};
