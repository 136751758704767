import * as Sentry from '@sentry/nextjs';
import React from 'react';
import { UseMutationOptions, useMutation, useQuery } from 'react-query';
import create from 'zustand';

import { config } from '@jernia/shared/lib/config';

import { useAuthStore } from './auth';
import { fetchWithAuth } from './fetch';
import { queryClient } from './query-client';

import { CustomerAutocompleteResponse } from '@jernia/shared/types/rest/customer-autocomplete';
import { User } from '@jernia/shared/types/rest/user';

type CustomerState = {
  pendingVippsLogin: string | null;

  loginAsCustomer: (customerId: string) => Promise<void>;
  loginWithVipps: (phoneNumber: string) => void;
  cancelVippsLogin: () => void;
};

export const useCustomerStore = create<CustomerState>((set) => ({
  pendingVippsLogin: null,

  loginAsCustomer: async (customerId) => {
    if (!customerId || customerId === 'anonymous') {
      return;
    }

    Sentry.setContext('customer', {
      id: customerId,
    });

    useAuthStore.getState().setLoggedInUser(customerId);
    queryClient.invalidateQueries('customer');
  },

  cancelVippsLogin: () => {
    set({ pendingVippsLogin: null });
  },

  loginWithVipps: async (phoneNumber) => {
    const response = await fetchWithAuth(`${config.apiBaseUrl}/vipps/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `mobilePhone=${phoneNumber}`,
    });

    if (response.ok) {
      set({ pendingVippsLogin: phoneNumber });
    }
  },
}));

export function useCustomer() {
  const { userId } = useAuthStore();

  return useQuery<User | undefined>(['customer', userId], async () => {
    if (!userId || userId === 'anonymous') {
      return undefined;
    }

    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/${userId}`
    );

    if (!response.ok) {
      throw await response.json();
    }

    return response.json();
  });
}

export function useUpdateUserMutation() {
  type Opts = {
    userId: string;
    user: Partial<User>;
  };
  return useMutation<unknown, unknown, Opts>(async ({ userId, user }) => {
    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/${userId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      }
    );

    if (!response.ok) {
      throw await response.json();
    }
  });
}

export function useOnboardCustomerMutation() {
  type Opts = {
    mobilePhone: string;
    firstName: string;
    lastName: string;
    email: string;
  };

  type Error = {
    message: string;
    type: string;
  };

  return useMutation<unknown, Error, Opts>(
    async ({ mobilePhone, firstName, lastName, email }) => {
      const response = await fetchWithAuth(`${config.apiBaseUrl}/onboard`, {
        method: 'POST',
        queryParams: {
          mobilePhone,
          firstName,
          lastName,
          login: email,
        },
      });

      if (!response.ok) {
        const body = await response.json();
        throw Array.isArray(body.errors) ? body.errors[0] : body;
      }
    }
  );
}

export function useOnboardCompleteMutation({
  onSuccess,
}: {
  onSuccess: UseMutationOptions<
    unknown,
    unknown,
    {
      mobilePhone: string;
    }
  >['onSuccess'];
}) {
  type Opts = {
    mobilePhone: string;
  };

  return useMutation<unknown, unknown, Opts>(
    async ({ mobilePhone }) => {
      const response = await fetchWithAuth(
        `${config.apiBaseUrl}/onboard/complete`,
        {
          queryParams: {
            mobilePhone,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Not completed sign up');
      }
    },
    {
      retry: 300,
      retryDelay: 2000,
      onSuccess,
    }
  );
}

function useGetLatest<T>(obj: T): () => T {
  const ref = React.useRef<T>(obj);
  ref.current = obj;

  return React.useCallback(() => ref.current, []);
}

// From: https://github.com/tannerlinsley/react-table/blob/35b8a74b80174ff5290c93553e753a83dfa41b7b/src/publicUtils.js#L163
function useAsyncDebounce<A extends any[], R>(
  defaultFn: (...args: A) => R,
  defaultWait = 0
) {
  const debounceRef = React.useRef<{
    timeout?: ReturnType<typeof setTimeout>;
    promise?: Promise<any>;
    resolve?: (value: any) => void;
    reject?: (err: any) => void;
  }>({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return React.useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve &&
            debounceRef.current.resolve(await getDefaultFn()(...(args as A)));
        } catch (err) {
          debounceRef.current.reject && debounceRef.current.reject(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait]
  );
}

export function fetchCustomers(
  query: string
): Promise<CustomerAutocompleteResponse> {
  let url = `${config.apiBaseUrl}/customers`;

  const isValidPhone = /\d{8}/.test(query);
  if (isValidPhone) {
    url = `${url}/autocompletephone?phone=${query}`;
  } else {
    url = `${url}/autocomplete?customerQuery=${query}`;
  }

  return fetchWithAuth(url).then((resp) => resp.json());
}

// Set to true to allow searching by e-mail etc.
const ENABLE_CUSTOMER_QUERY = true;

export function useCustomerAutocomplete(query: string) {
  const isValidPhone = /\d{8}/.test(query);

  return useQuery<CustomerAutocompleteResponse>(
    ['customers', query],
    useAsyncDebounce(async () => {
      const result = await fetchCustomers(query);
      if (result.length === 1 && result[0].email === null) {
        return [] as CustomerAutocompleteResponse;
      }
      return result;
    }, 300),
    {
      enabled: ENABLE_CUSTOMER_QUERY ? query.length >= 3 : isValidPhone,
      keepPreviousData: true,
    }
  );
}
export function useGetCustomer({ userId }: { userId: string }) {
  return useQuery<User | undefined>(['customer', userId], async () => {
    if (!userId || userId === 'anonymous') {
      return undefined;
    }

    const response = await fetchWithAuth(
      `${config.apiBaseUrl}/users/${userId}`
    );

    if (!response.ok) {
      throw await response.json();
    }

    return response.json();
  });
}

export type CustomerAutocompleteProps = {
  attributes: string;
  customerQuery: string;
  sort?:
    | 'byUidAsc'
    | 'byUidDesc'
    | 'byNameAsc'
    | 'byNameDesc'
    | 'byPhoneAsc'
    | 'byPhoneDesc';
};

export function useCustomerAutocompleteAdvancedQuery(
  props: CustomerAutocompleteProps
) {
  return useQuery<CustomerAutocompleteResponse>(
    ['customers/advanced', props],
    async () => {
      const params = new URLSearchParams(props);
      const url = `${config.apiBaseUrl}/customers/autocomplete/advanced?${params}`;
      const res = await fetchWithAuth(url);

      if (!res.ok) {
        throw await res.json();
      }

      return res.json();
    },
    {
      enabled: props.customerQuery !== '' && props.customerQuery.length >= 3,
    }
  );
}
