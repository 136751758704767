import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import { LoaderFadeIcon } from '@jernia/shared/components/icons/icons';

import { Logo } from '../logo/logo';

export function SplashScreen() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="pointer-events-none fixed inset-0 z-50 flex flex-col items-center justify-center bg-primary-800">
      <motion.div layoutId="logo" className="mb-4 shrink-0">
        <Logo />
      </motion.div>
      <LoaderFadeIcon className="animate-spin text-white" />
    </div>
  );
}
