import { ReactModal } from '@jernia/shared';
import type { AppLayoutProps } from 'next/app';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useRequireAuthentication } from '@jernia/shared/state/auth';
import { queryClient } from '@jernia/shared/state/query-client';

import { SplashScreen } from '../components/splash-screen/splash-screen';
import '../styles/globals.css';

declare global {
  interface Window {
    lipscoreInit: () => void;
    reInitWidgets: () => void;
    lipscore: any;
  }
}

if (typeof window !== 'undefined' && !window.lipscore) {
  window.lipscoreInit = function () {
    window.lipscore.init({
      apiKey: 'b043a32b7a98ea1bd2d7527b',
    });
  };
  (function () {
    const scr = document.createElement('script');
    scr.async = true;
    scr.src = '//static.lipscore.com/assets/no/lipscore-v1.js';
    document.getElementsByTagName('head')[0].appendChild(scr);
  })();
}

function AuthenticationWrapper({ children }: { children: React.ReactNode }) {
  const { showSplash } = useRequireAuthentication();
  return (
    <>
      {children}
      {showSplash && <SplashScreen />}
    </>
  );
}

ReactModal.setAppElement('#__next');

function JerniaHub({ Component, pageProps }: AppLayoutProps) {
  const getLayout = Component.getLayout || ((page: React.ReactNode) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationWrapper>
        {getLayout(<Component {...pageProps} />)}
      </AuthenticationWrapper>

      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
}

export default JerniaHub;
