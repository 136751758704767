import create from 'zustand';
import { devtools } from 'zustand/middleware';

export const createStore: typeof create = (
  store: Parameters<typeof create>[0]
) => {
  if (process.env.NODE_ENV === 'development') {
    return create(devtools(store as any));
  }
  return create(store);
};
